import { PackagePaymentType, TrialPackageType } from '../types';

export function getPriceFromTierData(options: {
  priceTierData: Record<string, any>;
  priceTier: number;
  selectedCountry?: string;
}) {
  const { priceTierData, priceTier, selectedCountry = 'us' } = options || {};
  const { price } = priceTierData[priceTier].priceList[selectedCountry.toLowerCase()];
  return price;
}

export function getCurrencySymbol(currency: string) {
  return currency.toUpperCase();
}

export function getPackageCurrency(packageItem: Record<string, any>) {
  const { priceTier, selectedCountry = 'us' } = packageItem || {};
  const { currency } = packageItem.priceTierData[priceTier].priceList[selectedCountry.toLowerCase()];
  return getCurrencySymbol(currency);
}

export function getPackagePrices(packageItem: Record<string, any>) {
  const {
    priceTierData,
    selectedPackageTier,
    priceTier,
    trialPriceTier,
    selectedCountry,
    trialPriceTierData,
    selectedPackageTrialTier,
    packageType,
    trialPackageType,
    savePercent = 0,
    dailyPrice,
    weeklyPrice,
    selectedPriceCurrency
  } = packageItem || {};
  const currency = getPackageCurrency(packageItem);
  const price = getPriceFromTierData({ priceTierData, priceTier:selectedPackageTier || priceTier, selectedCountry });
  let trialPrice = '0.00';
  if (packageType === PackagePaymentType.SUBSCRIPTION && trialPackageType === TrialPackageType.STARTING_PRICE) {
    trialPrice = getPriceFromTierData({
      priceTierData: trialPriceTierData,
      priceTier: selectedPackageTrialTier || trialPriceTier,
      selectedCountry
    });
  }

  return {
    price: `${price} ${currency}`,
    priceBase: price,
    currency,
    trialPrice: `${trialPrice} ${currency}`,
    trialPriceBase: trialPrice,
    savePercent,
    dailyPrice: dailyPrice ? `${dailyPrice} ${selectedPriceCurrency}` : `${price} ${currency}`,
    weeklyPrice: weeklyPrice ? `${weeklyPrice} ${selectedPriceCurrency}` : `${price} ${currency}`
  };
}

export function getPackagePeriodsInfo(packageItem: Record<string, any>) {
  const {
    period = 0,
    periodType = "",
    packageType,
    trialPeriod = 0,
    trialPeriodType = "",
    trialPackageType
  } = packageItem || {};

  const periodObj = { period, periodType }
  const trialPeriodObj = { trialPeriod, trialPeriodType }

  if (packageType === PackagePaymentType.CONSUMABLE) {
    return {
      ...periodObj,
      ...trialPeriodObj
    }
  }

  if (trialPackageType === TrialPackageType.NO) {
    trialPeriodObj.trialPeriod = 0;
    trialPeriodObj.trialPeriodType = "";
  }

  if (periodType === "day" && period > 0 && period % 7 === 0) {
    periodObj.period = period / 7;
    periodObj.periodType = "week";
  }

  if (trialPeriodType === "day" && trialPeriod > 0 && trialPeriod % 7 === 0) {
    trialPeriodObj.trialPeriod = trialPeriod / 7;
    trialPeriodObj.trialPeriodType = "week";
  }

  return {
    ...periodObj,
    ...trialPeriodObj
  }
}

export function getPackagesPrices(packageItems: Record<string, any>[]) {
  return packageItems.reduce((acc, curr) => {
    acc[curr.id] = {
      price: getPackagePrices(curr).price,
      trialPrice: getPackagePrices(curr).trialPrice,
      savePercent: getPackagePrices(curr).savePercent,
      dailyPrice: getPackagePrices(curr).dailyPrice,
      weeklyPrice: getPackagePrices(curr).weeklyPrice
    }
    return acc;
  }, {})
}

export function getTotalPayableAmount(packageItem: Record<string, any>, options?: { isTrialUsed?: boolean }) {
  if (!packageItem?.id) return '';
  const { isTrialUsed = false } = options || {};
  const {
    priceTierData,
    selectedPackageTier,
    selectedCountry,
    packageType,
    trialPackageType,
    trialPriceTierData,
    selectedPackageTrialTier
  } = packageItem;
  let price = getPriceFromTierData({ priceTierData, priceTier: selectedPackageTier, selectedCountry });
  const currency = getPackageCurrency(packageItem);
  if (packageType === PackagePaymentType.SUBSCRIPTION && !isTrialUsed) {
    switch (trialPackageType) {
      case TrialPackageType.FREE_TRIAL: {
        price = '0.00';
        break;
      }
      case TrialPackageType.STARTING_PRICE: {
        price = getPriceFromTierData({
          priceTierData: trialPriceTierData,
          priceTier: selectedPackageTrialTier,
          selectedCountry
        });
        break;
      }
      default:
        break;
    }
  }
  return `${price} ${currency}`;
}

export default getTotalPayableAmount;
